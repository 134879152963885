import './index.css'

import { BrowserRouter } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

const LanguageWrapper = React.lazy(() => import('./context/Language'));
const GlobalLoadingWrapper = React.lazy(() => import('./context/GlobalLoading'));
const AuthentificationWrapper = React.lazy(() => import('./context/Authentification'));
const App = React.lazy(() => import('./App'));
const DrawerMenuWrapper = React.lazy(() => import('./context/DrawerMenu'));

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.Suspense fallback={<CircularProgress />}>
    <GlobalLoadingWrapper>
      <LanguageWrapper>
        <AuthentificationWrapper>
          <BrowserRouter basename='/cpanel'>
            <DrawerMenuWrapper>
              <App />
            </DrawerMenuWrapper>
          </BrowserRouter>
        </AuthentificationWrapper>
      </LanguageWrapper>
    </GlobalLoadingWrapper>
  </React.Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
